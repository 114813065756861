<template>
  <b-sidebar
    id="add-new-raw-milk-sidebar"
    :visible="isAddNewPenSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-raw-milk-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Tambah Kandang Baru
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

           <!-- Nama Kandang -->
           <validation-provider
           #default="validationContext"
           name="Nama Kandang"
           rules="required"
         >
           <b-form-group
             label="Nama Kandang"
             label-for="pen_name"
           >
             <b-form-input
               id="pen_name"
               v-model="pen.name"
               autofocus
               :state="getValidationState(validationContext)"
               trim
               placeholder="Nama Kandang"
             />

             <b-form-invalid-feedback>
               {{ validationContext.errors[0] }}
             </b-form-invalid-feedback>
           </b-form-group>
         </validation-provider>

          <!-- Deskripsi -->
          <validation-provider
            #default="validationContext"
            name="Deskripsi"
            rules="required"
          >
            <b-form-group
              label="Deskripsi"
              label-for="descriptions"
            >
              <b-form-input
                id="descriptions"
                v-model="pen.descriptions"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Deskripsi Kandang"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Kapasitas -->
          <validation-provider
            #default="validationContext"
            name="Kapasitas"
            rules="required"
          >
            <b-form-group
              label="Kapasitas"
              label-for="unit"
            >
              <b-form-input
                id="unit"
                v-model="pen.max_capacity"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Kapasitas kandang"
                type="number"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Tambah
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Batal
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput,BFormRadioGroup, BFormInvalidFeedback, BButton, BFormDatepicker, BFormTimepicker } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BFormTimepicker,
    BFormInvalidFeedback,
    BButton,
    BFormRadioGroup,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewPenSidebarActive',
    event: 'update:is-add-new-pen-sidebar-active',
  },
  props: {
    isAddNewPenSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props, { emit }) {

    // use Toast
    const toast = useToast()
    // setup json post goat data
    const blankPenData = {
      pen: null,
      descriptions: null,
      max_capacity: null,
    }

    // define pen from JSON data to ref variable
    const pen = ref(JSON.parse(JSON.stringify(blankPenData)))

    // when click Cancle button
    const resetPen = () => {
      pen.value = JSON.parse(JSON.stringify(blankPenData))
    }

    // when click Add button
    const onSubmit = () => {

      store.dispatch('app-pen/addPen', pen.value)
      .then((response) => {
        if(response.data.data.status == 500){
          toast({
          component: ToastificationContent,
          props: {
            title: 'Gagal data kandang',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
        
        toast({
          component: ToastificationContent,
          props: {
            title: 'Berhasil menambahkan data kandang',
            icon: 'CheckIcon',
            variant: 'success',
          }
        })
        emit('refetch-data-summary')
        emit('update:is-add-new-pen-sidebar-active', false)

      })
  }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetPen)

    return {
      pen,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  created() {
    
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#add-new-goat-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
