<template>
    <div>
        <pen-add-new
            :is-add-new-pen-sidebar-active.sync="
                isAddNewPenSidebarActive
            "
            @refetch-data-summary="refetchPen"
        />

        <pen-edit
            :is-edit-pen-sidebar-active.sync="
                isEditPenSidebarActive
            "
            :penData="penDataEdit"
            @refetch-data-summary="refetchPen"
        />

        <!-- List produksi susu group by kambing -->
        <b-card no-body class="mb-0 mt-3">
            <!-- Table Top -->
            <b-row class="pt-2 pl-2 pr-2">
                <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                    <h4>Master Kandang</h4>
                </b-col>

                <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
                >
                    <b-button
                        variant="primary"
                        @click="
                            isAddNewPenSidebarActive =
                                !isAddNewPenSidebarActive
                        "
                    >
                        <span class="text-nowrap">+ Tambah Data</span>
                    </b-button>
                </b-col>
            </b-row>
            <hr />
            <b-row class="pl-2 pr-2 pb-2">
                <!-- Per Page -->
                <b-col
                    cols="12"
                    md="3"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                    <b-form-input
                        v-model="searchQueryPen"
                        class="d-inline-block mr-1"
                        placeholder="Search..."
                    />
                </b-col>

                <!-- Search -->
                <b-col cols="12" md="6">
                    <div
                        class="d-flex align-items-center justify-content-end"
                    ></div>
                </b-col>
            </b-row>

            <b-table
                ref="refPen"
                class="position-relative"
                :items="fetchPens"
                responsive
                :fields="tableColumnPen"
                primary-key="id"
                :sort-by.sync="sortByPen"
                show-empty
                empty-text="No matching records found"
                :sort-desc.sync="isSortDirDescPen"
            >
                <!-- Column: User -->
                <!-- <template #cell(user)="data">
                    <b-media vertical-align="center">
                        <template #aside>
                            <b-avatar
                                size="32"
                                :src="data.item.foto_produk"
                                :text="avatarText(data.item.fullName)"
                                :variant="`light-${resolveUserRoleVariant(
                                    data.item.role
                                )}`"
                                :to="{
                                    name: 'apps-users-view',
                                    params: { id: data.item.id },
                                }"
                            />
                        </template>
                        <b-link
                            :to="{
                                name: 'apps-users-view',
                                params: { id: data.item.id },
                            }"
                            class="font-weight-bold d-block text-nowrap"
                        >
                            {{ data.item.fullName }}
                        </b-link>
                    </b-media>
                </template> -->
                <template #cell(foto_produk)="data">
                    <b-avatar variant="light" :src="data.value" />
                </template>

                <!-- <template #cell(kode_warna)="data">
                    <b-badge
                        pill
                        class="text-capitalize"
                        :style="{ backgroundColor: data.item.kode_warna }"
                    >
                        {{ data.item.kode_warna }}
                    </b-badge>
                </template> -->

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                    >
                        <template #button-content>
                            <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                            />
                        </template>
                        <!-- <b-dropdown-item >
                            <feather-icon icon="FileTextIcon" />
                            <span class="align-middle ml-50"
                                >Detail Pencatatan</span
                            >
                        </b-dropdown-item> -->

                        <b-dropdown-item @click="handleEditData(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="handleDelete(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted"
                            >Menampilkan {{ dataMetaPen.from }} -
                            {{ dataMetaPen.to }} dari
                            {{ dataMetaPen.of }} data</span
                        >
                    </b-col>
                    <!-- Pagination -->
                    <!-- <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPen"
              :total-rows="totalPen"
              :per-page="perPagePen"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col> -->
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import usePenList from "./usePenList";
import masterPenStoreModule from "./masterPenStoreModule";
import PenAddNew from "./PenAddNew.vue";
import PenEdit from "./PenEdit.vue";


// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
    components: {
        PenAddNew,
        PenEdit,

        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,

        vSelect,
    },
    setup() {
        // use Toast
        const toast = useToast()

        const PEN_STORE_MODULE_NAME = "app-pen";

        // Register module
        if (!store.hasModule(PEN_STORE_MODULE_NAME))
            store.registerModule(
                PEN_STORE_MODULE_NAME,
                masterPenStoreModule
            );

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(PEN_STORE_MODULE_NAME))
                store.unregisterModule(PEN_STORE_MODULE_NAME);
        });

        const isAddNewPenSidebarActive = ref(false);
        const isEditPenSidebarActive = ref(false);

        const {
            fetchPens,
            tableColumnPen,
            perPagePen,
            currentPen,
            totalPen,
            dataMetaPen,
            perPageOptionsPen,
            searchQueryPen,
            sortByPen,
            isSortDirDescPen,
            refPen,

            refetchPen,
        } = usePenList();

        const handleDelete = async (idPen) => {
            await store.dispatch('app-pen/deletePen', {id: idPen}).then((response) => {
                if(response.data.status == 500){
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Gagal menghapus kandang',
                                icon: 'AlertTriangleIcon',
                                variant: 'danger',
                        },
                    })
                }
            })

            refetchPen();
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Berhasil menghapus kandang',
                    icon: 'CheckIcon',
                    variant: 'success',
                },
            })
        }

        const blankPenData = {
            id: null,
            name: null,
            max_capacity: null,
            descriptions: null,
            product_picture: '',
        }
    
        // define productname from JSON data to ref variable
        const penDataEdit = ref(JSON.parse(JSON.stringify(blankPenData)))

        const handleEditData = (penData) => {
            isEditPenSidebarActive.value = !isEditPenSidebarActive.value
            penDataEdit.value.id = penData.id;
            penDataEdit.value.name = penData.nama_kandang;
            penDataEdit.value.max_capacity = penData.kapasitas;
            penDataEdit.value.descriptions = penData.deskripsi;
        }

       
        return {
            // dummyData,
            // Sidebar
            isAddNewPenSidebarActive,
            isEditPenSidebarActive,
            handleDelete,
            handleEditData,
            penDataEdit,

            fetchPens,
            tableColumnPen,
            perPagePen,
            currentPen,
            totalPen,
            dataMetaPen,
            perPageOptionsPen,
            searchQueryPen,
            sortByPen,
            isSortDirDescPen,
            refPen,

            refetchPen,
        };
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
