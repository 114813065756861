import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPens(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/pens')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addPen(ctx, pen) {
      return new Promise((resolve, reject) => {
        axios
          .post('/pens', pen)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editPen(ctx, pen) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/pens/${pen.id}`, pen)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePen(ctx, category) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/pens/${category.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
