import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function usePenList() {
  // Use toast
  const toast = useToast()

  const refPen = ref(null)

  // Table Handlers
  const tableColumnPen = [
    { key: 'nama_kandang', sortable: false },
    { key: 'kapasitas', sortable: false },
    { key: 'deskripsi', sortable: false },
    { key: 'terakhir_diperbaruhi', sortable: false },
    { key: 'actions' },
  ]
  const perPagePen = ref(10)
  const totalGoatsPen = ref(0)
  const currentPagePen = ref(1)
  const perPageOptionsPen = [10, 25, 50, 100]
  const searchQueryPen = ref('')
  const sortByPen = ref('id')
  const isSortDirDescPen = ref(true)

  const dataMetaPen = computed(() => {
    const localItemsCount = refPen.value ? refPen.value.localItems.length : 0
    return {
      from: perPagePen.value * (currentPagePen.value - 1) + (localItemsCount ? 1 : 0),
      to: perPagePen.value * (currentPagePen.value - 1) + localItemsCount,
      of: totalGoatsPen.value,
    }
  })

  const refetchPen = () => {
    refPen.value.refresh()
  }

  watch([currentPagePen, perPagePen, searchQueryPen,], () => {
    refetchPen()
  })

  const mapData = ({id, name, descriptions, capacity, updated_at}) => ({
    id,
    nama_kandang: name,
    deskripsi: descriptions,
    kapasitas: capacity,
    terakhir_diperbaruhi:updated_at,
  })

  const fetchPens = (ctx, callback) => {
    store
      .dispatch('app-pen/fetchPens')
      .then(response => {
        const data = response.data.data
        const dataMapped = data.map(mapData)
        const { total } = response.data.data
        
        callback(dataMapped)
        totalGoatsPen.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Gagal mendapatkan data kandang',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchPens,
    tableColumnPen,
    perPagePen,
    currentPagePen,
    totalGoatsPen,
    dataMetaPen,
    perPageOptionsPen,
    searchQueryPen,
    sortByPen,
    isSortDirDescPen,
    refPen,

    refetchPen,

  }
}
